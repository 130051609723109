.farm-heading {
  color: #413ae2;
  font-size: 24px;
  font-weight: bold;
}

.farm-sub-heading {
  margin-top: 15px;
  font-size: 18px;
  color: #5f5f5f;
}

.farm-divider {
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 25px 0px;
}

.farm-phnx-eth {
  display: flex;
  align-items: center;
  width: 100%;
}

.farm-phnx-eth-logo {
  height: 35px;
  width: 35px;
  border-radius: 35px;
  /* border: solid 1px #73727d; */
}

.farm-details-div {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.farm-details-txt {
  font-size: 17px;
}

.farm-details-txt-right {
  display: flex;
  font-size: 18px;
  align-items: center;
  margin-left: auto;
}

.farm-btn-stake {
  background-color: #413ae2;
  width: 100%;
  font-size: 18px;
  color: #fff;
  height: 55px;
  border-radius: 9px;
  border: none;
  padding: 14px;
  margin-top: 35px;
}

.get-phnx-eth-lp {
  font-size: 16px;
  text-align: center;
  color: #413ae2;
  /* margin-right: 5px !important; */
}

.farm-btn-stake-outline {
  background-color: #fff;
  font-size: 14px;
  font-weight: bolder;
  color: #413ae2;
  border: solid 1px #413ae2;
  border-radius: 9px;
  width: 49%;
  padding: 13px;
  margin-top: 40px;
}

@media only screen and (max-width: 700px) {
  .farm-details-txt {
    font-size: 12px;
  }
}

@media only screen and (max-width: 480px) {
  .farm-btn-stake-outline {
    background-color: #fff;
    font-size: 12px;
    font-weight: bolder;
    color: #413ae2;
    border: solid 1px #413ae2;
    border-radius: 9px;
    width: 49%;
    padding: 13px;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 393px) {
  .farm-btn-stake-outline1 {
    height: 56px;
  }
  .farm-btn-stake-outline {
    height: 56px;
  }
}
