.switch-tabs-div{
    width: 400px;
    background-color: #fff;
    border-radius: 9px;
    padding: 3px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    box-shadow: 0px 10px 80px 10px rgba(0, 0, 0, 0.06);
}

.switch-tabs-btns{
    width: 50%;
    border-radius: 7px;
    background-color: #0b2ffa;
    text-align: center;
    user-select: none;
    padding: 7px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

@media only screen and (max-width: 630px){

    .switch-tabs-div{
        width: 90%;
    }

    .switch-tabs-btns{
        font-size: 15px;
    }

}
