.stakingModal {
  width: 400px;
  height: fit-content;
  background-color: #fff;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 25px;
}

.stakingModalLogo {
  width: 100px;
}

.stakingModalHeading {
  color: #091aff;
  font-size: 20px;
  font-weight: bold;
  margin-top: 7px;
}

.stakingModal-details {
  font-size: 16px;
  margin-top: 30px;
  color: #4e4e55;
  /* font-weight: bold; */
  display: flex;
  justify-content: center;
}

.stakingModalInput {
  font-size: 20px;
  font-weight: bolder;
  padding: 7px;
  flex-grow: 1;
  margin-right: 9px;
  color: #000000;
  border-radius: 3px;
  border: none;
  padding: 13px;
  color: #4e4e4e;
}

.stakingModalInput:focus {
  outline: none;
}

.stakingModalInputBtn {
  background-color: #091aff;
  color: #FFF;
  border-radius: 7px;
  border: none;
  font-weight: bold;
  height: 23px;
  font-size: 10px;
  height: 23px;
  width: 36px;
  padding: 4px 7px;
}

.stakingModalConfirm {
  background-color: #091aff;
  color: #fff;
}

@media only screen and (max-width: 700px) {
  .stakingModal {
    width: 90%;
  }

  .stakingModalInput {
    width: 50%;
  }
}

@media (max-width: 500px) {
  .stakingModal-details {
    font-size: 13px;
  }
}
