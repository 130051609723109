.transaction-liq-div{
  width: 500px;
  max-height: 600px;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 20px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  padding: 25px;
}

.transaction-liq-phnx-eth-det-div{
  background: #FFFFFF;
  border :solid 1px #413AE2;
  border-radius: 9px;
  padding-top: 12px;
  height: 50px;
  padding-bottom: 12px;
  padding-inline: 35px;
  font-size: 16px;
  font-weight: bolder;
text-align:center;
  margin-bottom:25px;
  color:#413AE2;

}

@media (max-width:400px){
  .transaction-liq-phnx-eth-det-div{
  background: #FFFFFF;
  border: solid 1px #413AE2;
  border-radius: 9px;
  padding-top: 12px;
  padding-bottom: 12px;
  height: 50px;
  padding-inline: 35px;
  font-size: 12px;
  font-weight: bolder;
  text-align: center;
  margin-bottom: 18px;
  color: #413AE2;
  }

  .transactionProgressMarginBottom{
    padding-bottom: 65px;
  }

  .transactionSubmittedCheck{
    text-align: center;
      padding-top: 10px !important;
  }
}

.transactionFireCheckIcon{
  height:188px;
  width: 188px;
}
.transactionProgressMarginBottom{
  padding-bottom: 85px;
}

.transactionProgress{
  color: #4E4E55;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  margin-top: 10px;
  text-align: center;
}
.iconMargin{
  margin-left:10px;
}

.transaction-liq-phnx-eth-det{
  display: flex;
  align-items: center;
}

.transaction-liq-phnx-eth-img{
  height: 24px;
  width: 24px;
}
.iconLeftMargin{
  margin-left:5px;
}
.textRecieve{
  font-size: 16px;
  color: #1E1E22;
}

.transaction-liq-phnx-eth-name{
  font-size: 18px;
  margin-left: 10px;
}
.cursorPointer{
  cursor: pointer;
}
.transaction-liq-phnx-eth-number{
  font-size: 18px;
  margin-left: auto;
  color: #4E4E55;
  
}

.transaction-liq-phnx-eth-con-div{
  width: 100%;
  margin-top: 9px;
}

.transaction-liq-phnx-eth-con{

  margin-left: auto;
  text-align: end;
  color: #4E4E55;
  margin-bottom: 9px;
}

.transaction-liq-btn{
  width: 40%;
  background-color: #413AE2;
  color: #fff;
  font-size: 18px;
  height: 50px;
  text-align: center;
  font-weight: bolder;
  border: none;
  border-radius: 9px;
  padding: 15px;
  margin: 13px 0px;
  margin-top: 0px;
}

.transaction-liq-divider{
  width: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  opacity: 0.6;
  margin: 7px 0px;
  height: 2px;
}
.fontWeight500{
  font-weight: 500;
}

.fontWeight400{
  font-weight: 400;
}
.phnxDepositePrice{
  align-items: center;
  color:#4E4E55;
    width: 50%;
    font-size: 16px;
    justify-content: end;
}
@media (max-width:400px){
  .phnxDepositePrice{
    align-items: center;
    color:#4E4E55;
      width: 60%;
      font-size: 13px;
      justify-content: end;
  }
}
.ratesFontColor{
  color: #5f5f5f;
  font-weight: 400px;

}
.justifySelfEnd{
  justify-self: self-end;
}
.displayInlineGrid{
  display: inline-grid;
}
.phnxDeposite{
  font-size: 14px;
  display: flex;
  font-weight: 500;
  width: 50%;;
  color:#4E4E55;
  text-transform: uppercase;
}
.closeModalIcon{
  color: rgba(0, 0, 0, 0.6);
  font-weight: 900;
  text-align: end;
  width: 50%;
}
@media (max-width:400px){
  .phnxDeposite{
    font-size: 12px;
    font-weight: 500;
    display: flex;
    width: 50%;;
    color:#4E4E55;
    text-transform: uppercase;
  }
}
.transaction-liq-phnx-eth-lp-div{
  display: flex;
  align-items: center;
}
.phnxDepositePriceImage{
  height: 20px;
  display: flex;
  width: 50%;;
  width: 20px;
  margin-right:3px;
}
.displayFlex{
  display: flex;
}
.transaction-liq-phnx-eth-lp-div > img {
  height: 25px;
  width: 25px;
  margin: 5px 3px;
}

.transaction-liq-phnx-eth-lp-sub{
  margin-left: 7px;
  color: #4E4E55;
  
}

.transaction-liq-phnx-eth-lp-sub-no{
  margin-left: auto;
  color: #4E4E55;
  font-weight: 900;
}








.transaction-liq-div::-webkit-scrollbar {
  width: 20px;
}

.transaction-liq-div::-webkit-scrollbar-track{
  background-color: transparent;
  height: 300px !important;
  margin: 15px 0px;
}
.transactionCloseButton{
  text-align: center;
  margin-bottom: 20px;
}
.transaction-liq-div::-webkit-scrollbar-thumb {
  background: rgba(56, 16, 255, 0.55) 0%;
  border-radius: 40px;
  border: solid 5px white;
  margin-top: 10px;
  margin-bottom: 10px;
}




@media (max-width: 600px) {
 
  .transaction-liq-div{
    width: 95%;
    padding-left: 25px 15px;
    max-height: 500px;
  }
  
  .transaction-liq-ps{
   font-size: 15px;
  }

}
