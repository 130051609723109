.farm-div{
    width: 600px;
    margin-top: 50px;
    padding: 50px 50px;
    background-color: #FFF;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 700px){

    .farm-div{
        width: 90%;
        margin-top: 20px;
        padding: 30px;
    }

}