div.container-div {
  margin-left: auto;
  margin-right: auto;
  width: 77%;
  height: 100%;
  margin-top: 70px;
  padding-bottom: 100px;
}
.cardPara {
  color: #1a1a1a;
  font-size: 50px;
  font-weight: bold;
  text-shadow: 0px 0px 1px #0b27fa;
  padding: 0px;
  margin: 0px;
}
.cardContent {
  display: flex;
  flex-flow: column;
}
div.gradient-div {
  display: flex;
  flex-direction: row;
  padding: 35px 50px;
  align-items: center;
  justify-content: space-between;
  background: none;
  box-sizing: border-box;
  background-color: #fff;
  border: 2px solid #eee;
  margin-right: 600px;
  /* -webkit-filter: ; */
  border-radius: 13px;
  position: relative;
}

div.connect-wallet-txt {
  font-size: 20px;
  text-align: left;
  width: 55%;
  /* margin: 34px 0px 40px 36px; */
  font-weight: 600;
}
img.img-hands {
  position: absolute;
  right: 47px;
  width: 250px;
  top: -40px;
  height: auto;
}

.css-jh47zj-MuiButtonBase-root-MuiButton-root {
  background: linear-gradient(309deg, rgba(18,91,214,1) 4%, rgba(17,26,245,1) 23%, rgba(10,60,219,1) 36%, rgba(9,45,217,1) 66%, rgba(8,57,222,1) 83%, rgba(16,71,238,1) 96%) !important;
  border-radius: 25px !important;
  stroke: #fff;
  stroke-width: 2;
  stroke-dasharray: 422, 0;
  transition: all 0.35s linear;
}

div.container-div2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  position: relative;
}
div.connect-wallet-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
p.phnx-heading {
  font-size: 40px;
  font-weight: bolder;
  text-shadow: 0px 0px 0px #000000;
  margin-bottom: 0;
}

@media (max-width: 500px) {
  div.gradient-div {
    display: flex;
    flex-direction: row;
    padding: 35px 0px;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(
      90deg,
      rgb(133, 186, 235) 0%,
      rgb(121, 177, 250) 70.13%
    ) !important;
    border: 0.5px solid #2864d34d;
    box-sizing: border-box;
    backdrop-filter: blur(2px);
    /* -webkit-filter: ; */
    border-radius: 13px;
    position: relative;
  }
}
@media (min-width: 1400px) {
  p.phnx-heading {
    font-size: 50px;
    font-weight: bolder;
    text-shadow: 0px 0px 0px #000000;
    margin-bottom: 0;
  }
}
p.phnx-subheading {
  font-size: 18px;
  color: #565656;
}
img.img-landing-logo {
  position: absolute;
  right: 0px;
  margin-top: 40px;
  z-index: -100;
  width: 400px;
  height: auto;
}

@media (max-width: 1220px) {
  img.img-landing-logo {
    width: 350px;
  }
}

@media (max-width: 1120px) {
  img.img-landing-logo {
    width: 300px;
    margin-top: 40px;
  }
}

@media (max-width: 1070px) {
  img.img-landing-logo {
    width: 270px;
    margin-top: 10px;
  }
}

/* @media (max-width: 1000px) {
  img.img-landing-logo {
    width: 270px;
    margin-top: -10px;
  }
}

@media (max-width: 990px) {
  img.img-landing-logo {
    width: 270px;
    right: 0;
    margin-top: 100px;
  }
}

@media (max-width: 930px) {
  img.img-landing-logo {
    width: 270px;
    right: 0;
    margin-top: 100px;
  }
}

@media (max-width: 880px) {
  img.img-landing-logo {
    width: 240px;
  }
  img.img-hands {
    width: 180px;
  }
}

@media (max-width: 840px) {
  img.img-landing-logo {
    width: 220px;
  }
}

@media (max-width: 815px) {
  
  p.phnx-subheading {
    
  }
  p.phnx-heading {
    font-size: 27px;
  }
  div.connect-wallet-txt {
    font-size: 18px;
  }

}

@media (max-width: 775px) {
  
  p.phnx-subheading {
    font-size: 15px;
  }
  p.phnx-heading {
    font-size: 25px;
  }
  div.connect-wallet-txt {
    font-size: 17px;
  }

} */
.cardContent {
  display: flex;
  flex-flow: column;
}
.pricesAlignment {
  display: flex;
}
@media (max-width: 1000px) {
  .pricesAlignment {
    display: flex;
    flex-direction: column;
  }
  div.container-div {
    margin-top: 50px;
  }

  .gradient-div {
    flex-direction: column !important;
  }

  p.phnx-subheading {
    font-size: 18px;
  }
  p.phnx-heading {
    font-size: 30px;
    margin-top: 200px;
    font-weight: bolder;
  }
  div.connect-wallet-txt {
    width: 50%;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    /* margin-bottom: 90px; */
  }

  img.img-landing-logo {
    width: 310px;
    top: -70px;
    margin-top: -10px;
  }

  img.img-hands {
    width: 150px;
    left: 10px;
    top: 90px;
  }
}

@media (max-width: 780px) {
  img.img-landing-logo {
    width: 280px;
    right: 0px;
  }
  p.phnx-heading {
    font-size: 25px;
    margin-top: 180px;
    font-weight: bolder;
  }
}

@media (max-width: 730px) {
  img.img-landing-logo {
    width: 250px;
  }
  p.phnx-heading {
    font-size: 25px;
    margin-top: 160px;
    font-weight: bolder;
  }
}

@media (max-width: 680px) {
  img.img-landing-logo {
    width: 230px;
  }
  p.phnx-heading {
    font-size: 25px;
    margin-top: 140px;
    font-weight: bold;
  }
  div.connect-wallet-txt {
    font-size: 18px;
  }
}

@media (max-width: 660px) {
  img.img-landing-logo {
    width: 210px;
  }
  p.phnx-heading {
    font-size: 25px;
    margin-top: 120px;
    font-weight: bold;
  }
}

@media (max-width: 645px) {
  p.phnx-subheading {
    font-size: 15px;
  }
  p.phnx-heading {
    font-size: 23px;
    margin-top: 150px;
    font-weight: bold;
  }
  div.connect-wallet-txt {
    font-size: 18px;
  }

  img.img-landing-logo {
    width: 240px;
    top: -60px;
  }

  img.img-hands {
    width: 130px;
    left: 10px;
    top: 85px;
  }

  div.container-div {
    width: 80%;
  }
}
.yourStakeHeading {
  font-size: 20px;
}

@media (max-width: 550px) {
  p.phnx-subheading {
    font-size: 14px;
  }
  p.phnx-heading {
    font-size: 21px;
    margin-top: 130px;
    font-weight: bold;
  }
  div.connect-wallet-txt {
    font-size: 17px;
    margin: 15px;
    margin-top: 20px;
    /* margin-bottom: 60px; */
  }

  img.img-landing-logo {
    width: 200px;
    top: -50px;
  }

  img.img-hands {
    width: 110px;
    left: 17px;
    top: 70px;
  }
}

@media (max-width: 480px) {
  div.container-div {
    margin-top: 30px;
  }
  .cardPara {
    color: rgb(0, 68, 255);
    font-size: 35px;
    font-weight: bold;
    text-shadow: 0px 0px 1px #292ce7;
    padding: 0px;
    margin: 0px;
  }

  p.phnx-subheading {
    font-size: 13px;
  }
  p.phnx-heading {
    font-size: 20px;
    margin-top: 0px;
    font-weight: bold;
  }
  div.connect-wallet-txt {
    font-size: 16px;
    margin: 5px;
    margin-top: 20px;
    /* margin-bottom: 60px; */
  }

  img.img-landing-logo {
    width: 90%;
    top: 180px;
    left: 0;
    right: 0;
  }

  img.img-hands {
    left: 55%;
    top: 60%;
  }
  .yourStakeHeading {
    font-size: 15px;
  }
}
