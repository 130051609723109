@font-face {
  font-family: "Aeonik";
  src: url("./custom-font/Aeonik-Regular.otf");
}
@font-face {
  font-family: "AeonikBold";
  src: url("./custom-font/Aeonik-Bold.otf");
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Aeonik" ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #e5e5e5 !important; */

  background-color: #F8FAFF !important;

}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
  font-family: "Aeonik" !important;
}

/* body > #root > div {
  height: 100vh;
} */
