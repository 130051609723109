.transactions-div {
  height: fit-content;
  width: 100%;
  padding: 25px 0px;
  max-height: 500px;
  margin-bottom: 30px;
}

.trans-div-child {
  display: flex;
  align-items: center;
}

.trans-div-child > * {
  margin: 5px;
  color: #5f5f5f;
  font-size: 19px;
  max-width: 80%;
  display: flex;
  align-items: center;
}

.trans-divider {
  width: 94%;
  background-color: rgba(0, 0, 0, 0.15);
  height: 1px;
  margin: 9px auto;
}

@media (max-width: 500px) {
  .transactions-div {
    padding: 12px 0px !important;
  }
}

.rm-liq-div::-webkit-scrollbar-thumb {
  height: 100px;
}
